<template>
  <div class="MK215">
    <div class="banner">
      <div class="title">MK 215</div>
      <div class="img">
        <img src="../../../assets/MK215页面配图/分组 17.png" alt="" />
      </div>
    </div>
    <div class="supports">
      <div class="content">
        <div v-show="supportsCon == 0">
          <div class="img">
            <img src="../../../assets/MK215页面配图/第三页后.png" alt="" />
          </div>
          <div class="line">
            <img src="../../../assets/MK215页面配图/背面.png" alt="" />
          </div>
          <div class="leftText">
            <div class="title">Wi-Fi</div>
            <div class="title">Bluetooth</div>
          </div>
          <div class="rightText">
            <div class="title">Metal</div>
          </div>
          <div class="button" @click="supportsBtn(1)">
            <div class="icon">
              <img src="../../../assets/ico/分组 1.png" alt="" />
            </div>
            <div class="title">Front</div>
          </div>
        </div>
        <div v-show="supportsCon == 1">
          <div class="bigtitle">Supports 10 touch points</div>
          <div class="img">
            <img src="../../../assets/MK215页面配图/第三页前.png" alt="" />
          </div>
          <div class="hand">
            <img src="../../../assets/ico/hand.png" alt="" />
          </div>
          <div class="button" @click="supportsBtn(0)">
            <div class="icon">
              <img src="../../../assets/ico/分组 1.png" alt="" />
            </div>
            <div class="title">Back</div>
          </div>
        </div>
      </div>
    </div>
    <ImgVideo :video="video" :img="img"></ImgVideo>
    <div class="printingAndQR">
      <div class="content w">
        <div class="left">
          <div class="img">
            <img src="../../../assets/MK215页面配图/第四页1.png" alt="" />
          </div>
          <div class="text">Printing</div>
        </div>
        <div class="right">
          <div class="img">
            <img src="../../../assets/MK215页面配图/第四页2.png" alt="" />
          </div>
          <div class="text">QR code Payment</div>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="line">
        <img src="../../../assets/MK215页面配图/爆炸图.png" alt="" />
      </div>
      <div class="topLeft">
        <div class="text">10-point capacitive touch screen</div>
      </div>
      <div class="topRight"><div class="text">7H hardness glass</div></div>
      <div class="rightCon">
        <div class="text">Fully laminated process</div>
      </div>
      <div class="bottomRight">
        <div class="text">80mm thermal printer with</div>
        <div class="text">Socket communication for agile</div>
        <div class="text">and rapid development</div>
      </div>
      <div class="bottomLeft">
        <div class="text">Aluminum alloy</div>
        <div class="text">structurally stable and lightweight</div>
      </div>
    </div>
    <div class="MK215Con">
      <div class="w">
        <div class="titleBox">
          <div class="title">MK 215</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">OS</div>
              <div class="text">Android 11</div>
            </div>
            <div class="itemA">
              <div class="title">Memory</div>
              <div class="text">RAM 4G</div>
              <div class="text">ROM 32GB(up to 128GB)</div>
            </div>
            <div class="itemB">
              <div class="title">Master Control</div>
              <div class="text">Rock Chips RK3566</div>
              <div class="text">ARM Cortex-A55 4 Cores 1.8GHz</div>
            </div>
            <div class="itemA">
              <div class="title">Printer</div>
              <div class="text">80mm High-speed printers</div>
            </div>
          </div>
          <div class="two">
            <div class="left">
              <div class="itemA">
                <div class="title">LCD screen</div>
                <div class="text">21.5-inch</div>
                <div class="text">high-definition screen</div>
              </div>
              <div class="itemA">
                <div class="title">Touch Screen</div>
                <div class="text">10 point capacitive screen</div>
              </div>
              <div class="itemA">
                <div class="title">Wireless</div>
                <div class="text">Wi-Fi 802.11b/g/n</div>
                <div class="text">Bluetooth 4.0</div>
                <div class="text">4G LTE (optional)</div>
              </div>
              <div class="itemA">
                <div class="title">Housing</div>
                <div class="text">Anodised Aluminium</div>
              </div>
              <div class="itemA">
                <div class="title">Color</div>
                <div class="text">Space Black</div>
                <div class="text">Space Gray</div>
                <div class="text">Midnight</div>
              </div>
              <div class="itemA">
                <div class="title">Certification</div>
                <div class="text">CCC, CE, FCC</div>
              </div>
              <div class="itemC">
                <div class="title">Interfaces</div>
                <div class="text">DC12Vpower port*1；</div>
                <div class="text">RJ9*1；</div>
                <div class="text">HDMI*1；</div>
                <div class="text">Audio/MIC*1；</div>
                <div class="text">
                  USB2.0*3 (One of them is a debugging port)；
                </div>
                <div class="text">USB3.0*1；</div>
                <div class="text">Ethernet*1；</div>
                <div class="text">Cash drawer*1</div>
              </div>
            </div>
            <div class="right">
              <div class="itemD">
                <div class="leftText">
                  <div class="title">Dimensions (L*W*H)</div>
                  <div class="text">366*230*1467mm</div>
                </div>
                <div class="rightImg">
                  <div class="top">366mm</div>
                  <div class="left">1467mm</div>
                  <div class="right">230mm</div>
                  <div class="img">
                    <img src="../../../assets/MK215页面配图/MK215.png" alt="" />
                  </div>
                </div>
              </div>
              <!-- <div class="itemC">
                <div class="title">Peripheral Ports</div>
                <div class="text">RJ45*1</div>
                <div class="text">DC-IN*1</div>
                <div class="text">RJ12*1</div>
                <div class="text">RJ11*1</div>
                <div class="text">USB2.0*4</div>
                <div class="text">TF Expansion card*1</div>
                <div class="text">3.5mm Audio output*1</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">MK 215</div>
              <div class="text">PDF(1.04MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/MK215.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <div class="itemB">
              <div class="title">MK 215 User Manual</div>
              <div class="text">PDF(1.04MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/MK215_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img from '../../../assets/MK215页面配图/第二页产品动图.gif'
import ImgVideo from '@/components/imgVideo'
import video from '@/assets/MK215页面配图/Compax_Mk_215_Video.mp4'
export default {
  data() {
    return {
      video: video,
      img: img,
      supportsCon: 0 //0后  1前
    }
  },
  components: {
    ImgVideo
  },
  methods: {
    supportsBtn(val) {
      this.supportsCon = val
    }
  }
}
</script>

<style lang="less" scoped>
.MK215 {
  .banner {
    position: relative;
    padding-top: 58px;
    height: 1080px;
    background: url('../../../assets/MK215页面配图/第一页动图.gif') no-repeat
      center;
    background-size: cover;
    .title {
      line-height: 86.88px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 60px;
      font-weight: 700;
    }
    .img {
      position: absolute;
      top: 327px;
      left: 427px;
      width: 1069.76px;
      height: 331.34px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .supports {
    padding-top: 73px;
    display: flex;
    justify-content: center;
    height: 1080px;
    background: rgba(0, 0, 0, 1);
    .content {
      position: relative;
      width: 1268px;
      height: 1008px;
      .bigtitle {
        position: absolute;
        top: 77px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        color: rgba(255, 255, 255, 1);
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
      }
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .img {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .leftText {
        text-align: center;
        width: 205px;
        position: absolute;
        z-index: 2;
        top: 408px;
        left: 112px;
      }
      .rightText {
        width: 376px;
        text-align: center;
        position: absolute;
        z-index: 2;
        top: 580px;
        right: 31px;
      }
      .button {
        cursor: pointer;
        display: flex;
        align-items: center;
        z-index: 2;
        position: absolute;
        top: 64px;
        left: 1042px;
        .icon {
          display: flex;
          // cursor: pointer;
          margin-right: 8px;
          width: 57.01px;
          height: 57px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .line {
        z-index: 2;
        position: absolute;
        width: 848px;
        height: 293.5px;
        top: 276px;
        left: 215px;
      }
      .hand {
        z-index: 2;
        position: absolute;
        top: 439px;
        left: 600px;
        width: 95.81px;
        height: 120px;
      }
    }
  }
  .printingAndQR {
    background: rgba(0, 0, 0, 1);
    height: 1080px;
    .content {
      padding-top: 222px;
      display: flex;
      justify-content: space-between;
      .text {
        color: rgba(255, 255, 255, 1);
        margin-top: 35px;
        font-size: 36px;
        font-weight: 600;
      }
      .left {
        width: 579.92px;
        text-align: center;
        .img {
          border: 2px solid rgba(128, 128, 128, 1);
          border-radius: 20px;
          width: 100%;
          height: 635.7px;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .right {
        width: 579.92px;
        text-align: center;
        .img {
          border: 2px solid rgba(128, 128, 128, 1);
          border-radius: 20px;
          width: 100%;
          height: 635.7px;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .product {
    position: relative;
    height: 1080px;
    background: url('../../../assets/MK215页面配图/第五页 爆炸图、.png')
      no-repeat center;
    background-size: cover;
    .line {
      width: 885px;
      height: 744px;
      position: absolute;
      top: 94px;
      left: 503px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      height: 46px;
      line-height: 35px;
    }
    .topLeft {
      position: absolute;
      top: 43px;
      left: 422px;
    }
    .topRight {
      position: absolute;
      top: 43px;
      right: 424px;
    }
    .rightCon {
      position: absolute;
      top: 534px;
      right: 427px;
    }
    .bottomRight {
      text-align: end;
      position: absolute;
      top: 847px;
      right: 423px;
    }
    .bottomLeft {
      position: absolute;
      top: 847px;
      left: 423px;
    }
  }
  .MK215Con {
    background: rgba(0, 0, 0, 1);
    padding: 103px 11px 0px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 36px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 95px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 459px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 700px;
        height: 237px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        position: relative;
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 460px;
        height: 563px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          color: rgba(255, 255, 255, 1);
        }
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 88px;
          right: 116px;
          .img {
            width: 230px;
            height: 460px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            height: 30px;
            line-height: 30px;
            position: absolute;
            top: -12px;
            left: 190px;
          }
          .left {
            height: 30px;
            line-height: 30px;
            position: absolute;
            left: -25px;
            top: 179px;
          }
          .right {
            width: fit-content;
            height: 30px;
            line-height: 30px;
            position: absolute;
            bottom: 12px;
            left: 203px;
          }
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          width: 700px;
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
        }
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
